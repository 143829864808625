<template>
    <div class="w-1/2 max-w-sm p-4 bg-black rounded-md bg-opacity-80">
        <p class="font-bold">Welcome to plane.</p>
        <p class="mt-2">$plane is meme token on solana!
          🚀 The IDO of $plane is live on @solana!
          Sending $SOL to:
          B48enPcv4XYn8gwyKgRcHkFYBkh6ZHm8gyXKJJW64ycC
          You'll directly participate in the IDO.There is no exact price,  will be allocated based on contribution  within 24 hours.
        </p>
        <p class="mt-4">
            If you have any questions you can reach on our twitter!
            <span class="font-bold text-opacity-50"><a href="https://twitter.com/planedogzks">@planedogzks</a></span>.
        </p>
    </div>
</template>

<script setup lang="ts"></script>